<template>
  <b-card-body>
    <table class="table table-bordered table-hover">
      <tr>
        <td>Institute</td>
        <td>Designation</td>
        <td>Worked(Years)</td>
        <td>Action</td>
      </tr>
      <tr v-for="(val,key) in working_experiences" :key="key">
        <td>{{ val.institute}}</td>
        <td>{{ val.designation }} </td>
        <td>{{ val.number_of_year }} </td>
        <td>
          <b-button  @click="selectRow(val)"
                     variant="primary">
            <feather-icon class="text-body " icon="EditIcon" size="15" />
          </b-button>
        </td>
      </tr>
    </table>
    <h4 class="mt-5">Add New/Update</h4>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="ed_q_form">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Institute') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('institute')"
                  rules="required"
              >
                <b-form-input
                    v-model="institute"
                    name="institute"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Carehomebd')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Designation') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('designation')"
                  rules="required"
              >
                <b-form-input
                    v-model="designation"
                    name="designation"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Assistance Professor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Number of working year') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('working year')"
                  rules="required"
              >
                <b-form-input
                    v-model="number_of_year"
                    name="number_of_year"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('2')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col class="mt-3">
            <b-button
                variant="danger"
                type="button" @click="clear()"
            >
              Clear
            </b-button>
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-body>
</template>
<script>
import {
  BCardBody,BButton, BFormGroup, BFormInput,
  BForm,BRow, BCol,BCardText,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {required} from '@validations'
import apiCall from "@/libs/axios";
export default {
  name:'WorkingExperience',
  components:{
    BCardBody,ValidationProvider, ValidationObserver,
    BButton, BFormGroup, BFormInput,
    BForm,BRow, BCol,BCardText,
  },
  props:{
    employee:{
      type:Object,
      required:true,
    }
  },
  data(){
    return{
      model_mode:'add',
      required,
      selected_row:{},
      institute:'',
      designation:'',
      number_of_year:'',
    }
  },
  methods:{
    clear(){
      this.model_mode='add';
      this.selected_row={};
      this.institute='';
      this.designation='';
      this.number_of_year='';
    },
    selectRow(row){
      this.selected_row=row;
      this.institute=row.institute;
      this.designation=row.designation;
      this.number_of_year=row.number_of_year;
      this.model_mode='edit';
    },
    validationForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.ed_q_form);
          if (this.model_mode ==='add'){
            apiCall.post(`/employee/experience/store${this.employee.id}`,data).then((response)=>{
              if (response.data.status==='success'){
                this.clear();
                this.$refs.simpleRules.reset();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_EMPLOYEE_WORKING_EXPERIENCE');
              }else this.$toaster.error(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/employee/experience/update${this.selected_row.id}`,data).then((response)=>{
              if (response.data.status==='success'){
                this.selected_row={};
                this.clear();
                this.$refs.simpleRules.reset();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_EMPLOYEE_WORKING_EXPERIENCE');
              }else this.$toaster.error(response.data.message);

            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    }
  },
  computed: {
    working_experiences(){
      return this.$store.getters.working_experiences.filter(item=>parseInt(item.employee_id)===parseInt(this.employee.id));
    }
  },
  created() {
    //this.$store.getters('GET_ALL_EMPLOYEE_WORKING_EXPERIENCE');
  }
}
</script>