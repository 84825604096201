<template>
  <b-card-code>
    <div v-if="$can('create','Employee')" class="custom-search d-flex mb-2">
      <b-button variant="outline-primary" @click="showModal">
        {{ $t('Add New') }}
      </b-button>
    </div>
    <div class="custom-search">
      <b-row>
        <b-col md="4">
          <b-card-text>
            <span>Select Department </span>
          </b-card-text>
          <validation-provider
              #default="{ errors }"
              name="Department"
              rules=""
          >
            <select
                v-model.number="s_department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
            >
              <option :value="null">Select One</option>
              <option
                  v-for="(department, key) in departments"
                  :value="department.id"
                  :key="key"
              >
                {{ department.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!-- <b-col md="3">
          <b-card-text>
            <span>Select Flat</span>
          </b-card-text>
          <validation-provider
              #default="{ errors }"
              name="version"
              rules=""
          >
            <select
                v-model.number="s_version_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
            >
              <option :value="null">Select One</option>
              <option
                  v-for="(version, key) in versions"
                  :value="version.id"
                  :key="key"
              >
                {{ version.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="3">
          <b-card-text>
            <span>Select Shift</span>
          </b-card-text>
          <validation-provider
              #default="{ errors }"
              name="shift"
              rules=""
          >
            <select
                v-model.number="s_shift_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
            >
              <option :value="null">Select One</option>
              <option
                  v-for="(shift, key) in shifts"
                  :value="shift.id"
                  :key="key"
              >
                {{ shift.name }}
              </option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col> -->
        <b-col md="3">
          <b-card-text>
            <span>Select Status</span>
          </b-card-text>
          <validation-provider
              #default="{ errors }"
              name="status"
              rules=""
          >
            <select
                v-model.number="status_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="form-control"
            >
              <option :value="null">Select One</option>
              <option :value="1" >{{ 'Active' }}</option>
              <option :value="0" >{{ 'InActive' }}</option>
            </select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="employees"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'image'">
          <template>
            <img :src="props.row.image?imgShow(props.row.image):'/student_face.gif'" width="50" height="50" alt="">
          </template>
        </span>
        <span v-else-if="props.column.field === 'department_id'">
          {{ departmentByID(props.row.department_id) }}
        </span>
        <span v-else-if="props.column.field === 'version_id'">
          {{ versionByID(props.row.version_id) }}
        </span>
        <span v-else-if="props.column.field === 'shift_id'">
          {{ shiftByID(props.row.shift_id) }}
        </span>

         <span v-else-if="props.column.field === 'is_result_shown'">
          <b-form-checkbox v-if="$can('status change','Employee')" :name="'check-button-result'+props.row.originalIndex" :id="'check-button-result'+props.row.originalIndex" @change="changeResultShownStatus(props.row.id,props.row.originalIndex)" v-model="props.row.is_result_shown" :value="1" switch>
          </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_result_shown)">
              {{ props.row.is_result_shown?'Active':'Deactivated' }}
            </b-badge>
        </span>

        <span v-else-if="props.column.field === 'is_active'">
          <b-form-checkbox v-if="$can('status change','Employee')" :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id,props.row.originalIndex)" v-model="props.row.is_active" :value="1" switch>
          </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_active)">
              {{ props.row.is_active?'Active':'Deactivated' }}
            </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button v-if="$can('read','Employee')" variant="primary" @click="viewDetails(props.row)">
                  <feather-icon class="text-body " icon="EyeIcon" size="15" />
              </b-button>
              <b-button v-if="$can('update','Employee')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="selectedRow(props.row)"
                        v-b-modal.modal-login variant="outline-secondary">
                  <feather-icon class="text-body " icon="EditIcon" size="15" />
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- modal login-->
    <b-modal ref="my-modal" hide-footer size="xl" :title="model_mode==='add'?$t('Add New'):$t('Information Edit')" >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form method="post" ref="add_or_update_form" @submit="validationForm" action="javascript:void(0)">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Photo') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('photo')"
                  rules=""
              >
                <b-form-file name="image" v-model="image" accept="image/png|image/jpeg|image/jpg"
                             :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                             :placeholder="$t('Select an image')"
                             class="form-control">
                </b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Name') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('name')"
                  rules="required"
              >
                <b-form-input name="name" v-model="name"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write name')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- <b-col md="4">
              <b-card-text>
                <span>{{ $t('Employee ID') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('institute_emp_id')"
                  rules="required"
              >
                <b-form-input name="institute_emp_id" v-model="org_emp_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write employee id')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Phone Number') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('phone number')"
                  rules="required"
              >
                <b-form-input name="phone_number" v-model="phone_number"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write phone number')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Email') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('email')"
                  rules=""
              >
                <b-form-input name="email" v-model="e_email"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write student email')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
           
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Role') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('role')"
                  rules="required"
              >
                <select name="role_id" v-model="role_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(role, key) in employeeRoles" :value="role.id" :key="key">{{role.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Designation') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('designation')"
                  rules="required"
              >
                <select name="designation_id" v-model="designation_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(designation, key) in designations" :value="designation.id" :key="key">{{designation.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Scale') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('scale')"
                  rules="required"
              >
                <select name="scale_id" v-model="scale_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(scale, key) in scales" :value="scale.id" :key="key">{{scale.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Grade') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('grade')"
                  rules="required"
              >
                <select name="designation_scale_id" v-model="designation_scale_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(grade, key) in grades" :value="grade.id" :key="key">{{grade.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Version') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('version')"
                  rules="required"
              >
                <select name="version_id" v-model="version_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(version, key) in versions" :value="version.id" :key="key">{{version.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Shift') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('shift')"
                  rules="required"
              >
                <select name="shift_id" v-model="shift_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(shift, key) in shifts" :value="shift.id" :key="key">{{shift.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Department') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('department')"
                  rules="required"
              >
                <select name="department_id" v-model="department_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(department, key) in departments" :value="department.id" :key="key">{{department.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Institution') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('wing')"
                  rules=""
              >
                <select name="wing_id" v-model="wing_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(wing, key) in wings" :value="wing.id" :key="key">{{wing.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Gender') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('gender')"
                  rules="required"
              >
                <select class="form-control" id="gender_id"
                        v-model="gender_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" name="gender_id">
                  <option :value="null">Select One</option>
                  <option v-for="(gender, key) in genders" :value="gender.id" :key="key">{{ gender.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Religion') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('religion')"
                  rules="required"
              >
                <select class="form-control" id="religion_id"
                        v-model="religion_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" name="religion_id">
                  <option :value="null">Select One</option>
                  <option v-for="(religion, key) in religions" :value="religion.id" :key="key">{{ religion.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Blood Group') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('blood group')"
                  rules="required"
              >
                <select class="form-control" id="blood_group"
                        v-model="blood_group_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" name="blood_group_id">
                  <option :value="null">Select One</option>
                  <option v-for="(bgrp, key) in blood_groups" :value="bgrp.id" :key="key">{{ bgrp.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Father Name') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('father name')"
                  rules=""
              >
                <b-form-input name="father_name" v-model="father_name"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write father name')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Mother Name') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('mother name')"
                  rules=""
              >
                <b-form-input name="mother_name" v-model="mother_name"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write mother name')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('NID NO') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('nid no')"
                  rules=""
              >
                <b-form-input name="nid" v-model.number="nid" type="number"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write nid no here')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Nationality') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('nationality')"
                  rules=""
              >
                <b-form-input name="nationality" v-model.number="nationality"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('nationality')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Marital status') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('marital status')"
                  rules="required"
              >
                <select name="marital_status_id" v-model="marital_status_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(mstatus, key) in marital_statuses" :value="mstatus.id" :key="key">{{mstatus.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Spouse Name ') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('spouse name ')"
                  rules=""
              >
                <b-form-input name="spouse_name" v-model.number="spouse_name"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write spouse name here')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Spouse Occupation') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Spouse Occupation')"
                  rules=""
              >
                <b-form-input name="spouse_occupation" v-model.number="spouse_occupation"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write Spouse Occupation here')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Emergency Contact No') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('emergency contact')"
                  rules="required"
              >
                <b-form-input name="emergency_contact" v-model="emergency_contact"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write emergency contact no')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Joining Date') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('join date')"
                  rules="required"
              >
                <b-form-datepicker name="join_date" v-model="join_date"
                                   :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                   :placeholder="$t('Select join date')"
                                   class="form-control">
                </b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Present Address') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('present address')"
                  rules=""
              >
                <b-form-textarea name="present_address" v-model="present_address"
                                 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                 :placeholder="$t('write present address')"
                                 class="form-control">
                </b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Permanent Address') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('permanent address')"
                  rules=""
              >
                <b-form-textarea name="permanent_address" v-model="permanent_address"
                                 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                 :placeholder="$t('write permanent address')"
                                 class="form-control">
                </b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- submit button -->
          <b-col>
            <b-button variant="primary" type="submit" >Submit</b-button>
          </b-col>
        </form>
      </validation-observer>
    </b-modal>

    <!-- view model -->
      <b-modal ref="information-view" hide-footer size="xl" :title="$t('Information View')" >
          <b-row>
              <b-col lg="4">
                  <b-card-group deck>
                      <b-card>
                          <div class="text-center">
                              <b-avatar :src="selected_row.image?imgShow(selected_row.image):'/student_face.gif'" size="6rem"></b-avatar>
                              <h1 class="mb-0">{{(selected_row.userable) ? selected_row.userable.name : ''}}</h1>
                              <h6 class="my-2">Employee</h6>
                          </div>
                          <table>
                              <tr>
                                  <td class="font-weight-bold">Employee Id</td>
                                  <td>: {{(selected_row.userable) ? selected_row.userable.phone_number : ''}}</td>
                              </tr>
                              <tr>
                                  <td class="font-weight-bold">Role</td>
                                  <td>: {{selected_row.userable ? roleByID(selected_row.userable.role_id) : ''}}</td>
                              </tr>
                              <tr>
                                  <td class="font-weight-bold">Designation </td>
                                  <td>: {{designationByID(selected_row.designation_id)}}</td>
                              </tr>
                          </table>
                      </b-card>
                  </b-card-group>
              </b-col>
              <b-col lg="8">
                  <b-card>
                      <b-tabs pills card>
                          <b-tab title="Profile" active >
                              <b-card-body>
                                  <b-row>
                                      <b-col  md="6">
                                          <table>
                                              <tr>
                                                  <td> Email</td>
                                                  <td>: {{(selected_row.userable) ? selected_row.userable.email : ''}}</td>
                                              </tr>
                                              <tr>
                                                  <td> Phone number</td>
                                                  <td>: {{(selected_row.userable) ? selected_row.userable.phone_number : ''}}</td>
                                              </tr>
                                              <tr>
                                                  <td>Gender</td>
                                                  <td>: {{selected_row.userable ? genderById(selected_row.userable.gender_id) : ''}}</td>
                                              </tr>
                                              <tr>
                                                  <td>Blood group</td>
                                                  <td>: {{bloodGroupById((selected_row.userable) ? selected_row.userable.blood_group_id : '')}}</td>
                                              </tr>
                                              <tr>
                                                  <td>Religion</td>
                                                  <td>: {{religionById((selected_row.userable) ? selected_row.userable.religion_id : '')}}</td>
                                              </tr>
                                              <tr>
                                                  <td>Emergency contact</td>
                                                  <td>: {{selected_row.emergency_contact}}</td>
                                              </tr>
                                          </table>

                                      </b-col>
                                      <b-col  md="6">
                                          <table>
                                              <tr>
                                                  <td>Father Name </td>
                                                  <td>: {{selected_row.father_name}}</td>
                                              </tr>
                                              <tr>
                                                  <td>Mother Name</td>
                                                  <td>: {{selected_row.mother_name}}</td>
                                              </tr>
                                              <tr>
                                                  <td>NID</td>
                                                  <td>: {{selected_row.nid}}</td>
                                              </tr>
                                              <tr>
                                                  <td>Marital status</td>
                                                  <td>: {{maritalStatusById(selected_row.marital_status_id)}}</td>
                                              </tr>

                                              <tr>
                                                  <td>Present address</td>
                                                  <td>: {{selected_row.present_address}}</td>
                                              </tr>
                                              <tr>
                                                  <td>Permanent address</td>
                                                  <td>: {{selected_row.permanent_address}}</td>
                                              </tr>
                                          </table>
                                      </b-col>
                                  </b-row>
                              </b-card-body>
                          </b-tab>
                          <b-tab title="Employment">
                              <b-card-body>
                                  <b-row>
                                      <b-col md="6">
                                          <table>
                                              <tr>
                                                  <td>Designation</td>
                                                  <td>: {{designationByID(selected_row.designation_id)}}</td>
                                              </tr>
                                              <tr>
                                                  <b-card-text>Scale</b-card-text>
                                                  <td>: {{scaleByID(selected_row.scale_id)}}</td>
                                              </tr>
                                              <tr>
                                                  <td>Designation Scale</td>
                                                  <td>: {{designationScaleByID(selected_row.designation_scale_id)}}</td>
                                              </tr>
                                          </table>
                                      </b-col>
                                      <b-col md="6">
                                          <table>
                                              <tr>
                                                  <td>Department</td>
                                                  <td>: {{departmentByID(selected_row.department_id)}}</td>
                                              </tr>
                                              <!-- <tr>
                                                  <td>Wing</td>
                                                  <td>: {{wingByID(selected_row.wing_id)}}</td>
                                              </tr> -->
                                              <tr>
                                                  <td>Join date</td>
                                                  <td>: {{selected_row.join_date}}</td>
                                              </tr>
                                              <tr v-if="selected_row.leave_date">
                                                  <td>Leave date</td>
                                                  <td>: {{selected_row.leave_date }}</td>
                                              </tr>
                                              <tr>
                                                  <td>Status</td>
                                                  <td :style="selected_row.is_active?'color:green':'color:red'">: {{(selected_row.is_active === 1) ? 'active': 'inactive' }}</td>
                                              </tr>
                                          </table>
                                      </b-col>
                                  </b-row>
                              </b-card-body>
                          </b-tab>
                        <b-tab title="Educational Qualifications">
                          <education-qualification :employee="selected_row"/>
                        </b-tab>
                        <b-tab title="Working Experience">
                          <working-experience :employee="selected_row"/>
                        </b-tab>
                      </b-tabs>
                  </b-card>
              </b-col>
          </b-row>
      </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,BCardText,BFormFile,BFormCheckbox,
  BRow, BCol,BFormDatepicker,BFormTextarea,BAvatar, BCardGroup, BCard, BCardBody, BTabs, BTab
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,integer,email,
} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
import EducationQualification from "@/views/employee/EducationQualification";
import WorkingExperience from "@/views/employee/WorkingExperience";
export default {
  name:'Employee',
  components: {
    EducationQualification,WorkingExperience,
    BCardCode,ValidationProvider,ValidationObserver,
    VueGoodTable,BCardText,BFormFile,BRow,BCol,BFormDatepicker,
    BFormTextarea,BAvatar,
    BPagination,BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
      BCardGroup, BCard, BCardBody, BTabs, BTab, VueJsonToCsv,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,integer,email,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      name:'',
      role_id:null,
      image:null,
      e_email:'',
      org_emp_id:'',
      phone_number:'',
      designation_id:null,
      scale_id:null,
      designation_scale_id:null,
      version_id:null,
      shift_id:null,
      wing_id:null,
      department_id:null,
      gender_id:null,
      blood_group_id:null,
      religion_id:null,
      father_name:'',
      mother_name:'',
      nid:'',
      marital_status_id:null,
      spouse_name:'',
      spouse_occupation:'',
      nationality:'',
      emergency_contact:'',
      present_address:'',
      permanent_address:'',
      join_date:null,
      selected_row:{},
      columns: [
        {
          label: this.$t('SL'),
          field: 'id',
        },
        {
          label: this.$t('Photo'),
          field: 'image',
        },
        {
          label: this.$t('Phone Number'),
          field: 'userable.phone_number',
        },
        {
          label: this.$t('Name'),
          field: 'userable.name',
        },
        {
          label: this.$t('Department'),
          field: 'department_id',
        },
        {
          label: this.$t('Status'),
          field: 'is_active',
        },
        {
          label: this.$t('Action'),
          field: 'action',
        },
      ],
      searchTerm: '',
      status: {
        1: this.$t('active'),
        0: this.$t('deactivate'),
      },
      columns10: [
        {
          label: 'EmployeeId',
          field: 'institute_emp_id',
        },
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'Role',
          field: 'Role',
        },
        {
          label: 'Designation',
          field: 'Designation',
        },
        {
          label: 'Scale',
          field: 'Scale',
        },
        {
          label: 'Designation Scale',
          field: 'DesignationScale',
        },
        {
          label: 'Department',
          field: 'Department',
        },
        {
          label: 'Shift',
          field: 'Shift',
        },
        {
          label: 'Wing',
          field: 'Wing',
        },
        {
          label: 'Version',
          field: 'Version',
        },
        {
          label: 'Join Date',
          field: 'JoinDate',
        },
        {
          label: 'Leave Date',
          field: 'LeaveDate',
        },
        {
          label: 'Email',
          field: 'Email',
        },
        {
          label: 'Gender',
          field: 'Gender',
        },
        {
          label: 'Religion',
          field: 'Religion',
        },
        {
          label: 'Phone Number',
          field: 'PhoneNumber',
        },
        {
          label: 'Blood Group',
          field: 'BloodGroup',
        },
        {
          label: 'Emergency Contact',
          field: 'EmergencyContact',
        },
        {
          label: 'Father Name',
          field: 'FatherName',
        },
        {
          label: 'Mother Name',
          field: 'MotherName',
        },
        {
          label: 'NID',
          field: 'NID',
        },
        {
          label: 'Present Address',
          field: 'PresentAddress',
        },
        {
          label: 'Permanent Address',
          field: 'PermanentAddress',
        },
        {
          label: 'Marital Status',
          field: 'MaritalStatus',
        },
        {
          label: 'Status',
          field: 'Status',
        },
      ],
      s_department_id:null,
      s_version_id:null,
      s_shift_id:null,
      status_id:null,
    }
  },
  methods:{
    viewDetails(row){
      this.selected_row=row;
      this.$refs['information-view'].show()
    },
    selectedRow(row){
      this.model_mode='edit';
      this.selected_row=row;
      this.name= row.userable.name? row.userable.name: '';
      this.role_id=row.userable.role_id;
      this.image=null;
      this.e_email=row.userable.email;
      this.org_emp_id = row.institute_emp_id;
      this.phone_number=row.userable.phone_number;
      this.designation_id=row.designation_id;
      this.scale_id=row.scale_id;
      this.designation_scale_id=row.designation_scale_id;
      this.version_id=row.version_id;
      this.shift_id=row.shift_id;
      this.wing_id=row.wing_id;
      this.department_id=row.department_id;
      this.gender_id=row.userable.gender_id;
      this.blood_group_id=row.userable.blood_group_id;
      this.religion_id=row.userable.religion_id;
      this.father_name=row.father_name;
      this.mother_name=row.mother_name;
      this.nid=row.nid;
      this.spouse_name=row.spouse_name;
      this.spouse_occupation=row.spouse_occupation;
      this.nationality=row.nationality;
      this.marital_status_id=row.marital_status_id;
      this.emergency_contact=row.emergency_contact;
      this.present_address=row.present_address;
      this.permanent_address=row.permanent_address;
      this.join_date=row.join_date;
      this.$refs['my-modal'].show()
    },
    imgShow(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    },
    async statusChange(id,index){
      await apiCall.put(`/employee/status/change${id}`).then((response)=>{
        if (response.data.status==='success'){
          this.$toaster.success(response.data.message);
          this.$store.commit('STATUS_CHANGE_EMPLOYEE',index);
        }else{
          this.$store.dispatch('GET_ALL_EMPLOYEE');
        }
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
      });
    },
    async changeResultShownStatus(id, index){
      await apiCall.put(`/employee/result/shown/status/change${id}`).then((response)=>{
        if (response.data.status==='success'){
          this.$toaster.success(response.data.message);
          this.$store.commit('RESULT_SHOWN_STATUS_CHANGE_EMPLOYEE', index);
        }else{
          this.$store.dispatch('GET_ALL_EMPLOYEE');
        }
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
      });
    },
    showModal() {
      this.model_mode='add';
      this.selected_row= {};
      this.name='';
      this.role_id=null;
      this.image=null;
      this.e_email='';
      this.institute_emp_id= '';
      this.phone_number='';
      this.designation_id=null;
      this.scale_id=null;
      this.designation_scale_id=null;
      this.version_id=null;
      this.shift_id=null;
      this.wing_id=null;
      this.department_id=null;
      this.gender_id=null;
      this.blood_group_id=null;
      this.religion_id=null;
      this.father_name='';
      this.mother_name='';
      this.nid='';
      this.spouse_name='';
      this.spouse_occupation='';
      this.nationality='';
      this.marital_status_id=null;
      this.emergency_contact='';
      this.present_address='';
      this.permanent_address='';
      this.join_date=null;
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_or_update_form);
          if (this.model_mode === 'add'){
            apiCall.post(`/employee/store`,data).then((response)=>{
              if (response.data.status === 'success'){
                this.$toaster.success(response.data.message);
                this.hideModal();
                this.$store.dispatch('GET_ALL_EMPLOYEE');
                this.$refs.simpleRules.reset();
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else{
            apiCall.post(`/employee/update${this.selected_row.id}`,data).then((response)=>{
              if (response.data.status === 'success'){
                this.$toaster.success(response.data.message);
                this.hideModal();
                this.$store.dispatch('GET_ALL_EMPLOYEE');
                this.$refs.simpleRules.reset();
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    pdfGenerate() {
      let title = "Employees";
      let clm = ['EmployeeId', 'Name', 'Role', 'Designation', 'Scale', 'DesignationScale', 'Department', 'Shift', 'Wing', 'Version', 'JoinDate', 'LeaveDate', 'Email', 'Gender', 'Religion', 'PhoneNumber', 'BloodGroup', 'EmergencyContact', 'FatherName', 'MotherName', 'NID', 'PresentAddress', 'PermanentAddress', 'MaritalStatus','Status' ];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/commom/landscape/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'employee_list.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters([
      'versions','versionByID','shifts','shiftByID','departments','departmentByID',
      'designations','designationByID','scales','scaleByID','designation_scales','designationScaleByID',
      'religions', 'religionById','genders','genderById','blood_groups','bloodGroupById','wings',
      'wingByID','marital_statuses','maritalStatusById','roles','roleByID','employeeRoles'
    ]),
    employees(){
      let std = this.$store.getters.employees;
      if(this.s_department_id) std =std.filter(item=>item.department_id===this.s_department_id);
      if(this.s_version_id) std =std.filter(item=>item.version_id===this.s_version_id);
      if(this.s_shift_id) std =std.filter(item=>item.shift_id===this.s_shift_id);
      if(this.s_student_group_id) std =std.filter(item=>item.student_group_id===this.s_student_group_id);
      if(this.status_id != null) std =std.filter(item=>item.is_active===this.status_id);
      return std;
    },
    getData(){
      return this.employees.map(item => ({
        EmployeeId: item.employee_id?item.employee_id:'',
        Name: item.userable?item.userable.name:'',
        Role: item.userable? this.roleByID(item.userable.role_id):'',
        Designation: item.designation_id?this.designationByID(item.designation_id):'',
        Scale: item.scale_id?this.scaleByID(item.scale_id):'',
        DesignationScale: item.designation_scale_id?this.designationScaleByID(item.designation_scale_id):'',
        Department: item.department_id?this.departmentByID(item.department_id):'',
        Shift: item.shift_id?this.shiftByID(item.shift_id):'',
        Wing: item.wing_id?this.wingByID(item.wing_id):'',
        Version: item.version_id?this.versionByID(item.version_id):'',
        JoinDate: item.join_date?item.join_date:'',
        LeaveDate: item.leave_date?item.leave_date:'',
        Email: item.userable?item.userable.email:'',
        Gender: item.userable?this.genderById(item.userable.gender_id):'',
        Religion: item.userable?this.religionById(item.userable.religion_id):'',
        PhoneNumber: item.userable?item.userable.phone_number:'',
        BloodGroup: item.userable?this.bloodGroupById(item.userable.blood_group_id):'',
        EmergencyContact: item.emergency_contact?item.emergency_contact:'',
        FatherName: item.father_name?item.father_name:'',
        MotherName: item.mother_name?item.mother_name:'',
        NID: item.nid?item.nid:'',
        PresentAddress: item.permanent_address?item.permanent_address:'',
        PermanentAddress: item.present_address?item.present_address:'',
        MaritalStatus: item.marital_status_id ?this.maritalStatusById(item.marital_status_id) :'',
        Status: item.is_active ? 'Active' : 'Inactive',
      }));
    },
    grades(){
      return this.$store.getters.designation_scales.filter(item=>item.designation_id == this.designation_id && item.scale_id ==this.scale_id);
    }
  },
  created() {
    if (this.employees.length <1 ) this.$store.dispatch('GET_ALL_EMPLOYEE');
    if (this.versions.length <1 ) this.$store.dispatch('GET_ALL_VERSION');
    if (this.shifts.length <1 ) this.$store.dispatch('GET_ALL_SHIFT');
    if (this.departments.length <1 ) this.$store.dispatch('GET_ALL_DEPARTMENT');
    if (this.designations.length <1 ) this.$store.dispatch('GET_ALL_DESIGNATION');
    if (this.scales.length <1 ) this.$store.dispatch('GET_ALL_SCALE');
    if (this.designation_scales.length <1 ) this.$store.dispatch('GET_ALL_DESIGNATION_SCALE');
    if (this.wings.length <1 ) this.$store.dispatch('GET_ALL_WING');
    if(this.religions.length<1) this.$store.dispatch('GET_ALL_RELIGION');
    if(this.blood_groups.length<1) this.$store.dispatch('GET_ALL_BLOOD_GROUP');
    if(this.genders.length<1) this.$store.dispatch('GET_ALL_GENDER');
    if(this.marital_statuses.length<1) this.$store.dispatch('GET_ALL_MARITAL_STATUS');
    if(this.roles.length<1) this.$store.dispatch('GET_ALL_ROLE');
    this.$store.dispatch('GET_ALL_EMPLOYEE_EDUCATIONAL_QUALIFICATION');
    this.$store.dispatch('GET_ALL_EMPLOYEE_WORKING_EXPERIENCE');
  },
  watch:{
    designation_id(){
      if(this.model_mode !=='edit') this.designation_scale_id=null;
    },
    scale_id(){
      if(this.model_mode !=='edit') this.designation_scale_id=null;
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
